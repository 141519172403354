// //////////////////////////////
// IMPORT ABSTRACT CSS FILES
//
// The abstracts/ directory gathers all Sass tools and helpers used across the project.
// Every global variable, function, mixin and placeholder should be put in here.
// The rule of thumb for all files within the abstracts/ directory is that it should
// not output a single line of CSS when compiled on its own. These are nothing but Sass helpers.
@import '../../scss/abstracts/breakpoint';
@import '../../scss/abstracts/functions';
@import '../../scss/abstracts/mixins';
@import '../../scss/abstracts/placeholders';
@import '../../scss/abstracts/variables';

/*--------------------------------------------------------------
# components / Info Box Three Column
--------------------------------------------------------------*/

$main-page-breakint: $laptop-m;

:local {
    .info-box-three-column {
      background-color: $color__spacegrey;
    }

    .info-box-three-column__column p {
      margin-top: 0.7rem;
      margin-bottom: 1.5rem;
    }

    .info-box-three-column__wrapper {
      padding-top: 1.5rem;
      padding-bottom: 2rem;
      color: $color__dirty-white;
      background-color: $color__spacegrey;

      @include breakpoint($main-page-breakint) {
        padding-right: 0;
      }
    }

    // //////////////////////////////
    // Info Box Three Column > Columns

    .info-box-three-column__three-columns {
      display: flex;
      // flex-direction: column;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    .info-box-three-column__column {
      flex: 1 1 100%;
      padding-top: 0.5rem;
      padding-right: 0.5rem;
      padding-bottom: 0.5rem;
      margin-top: 2rem;
      // background: blueviolet;

      @include breakpoint($main-page-breakint) {
        display: flex;
        flex: 1 1 28.33%;
        flex-direction: row;
        flex-wrap: wrap;
        // max-width: 20%;
        margin-right: 5%;
        // background: tomato;
      }
    }

    .info-box-three-column__column a,
    .info-box-three-column__column button {
      align-self: flex-end;
    }


}

