// //////////////////////////////
// IMPORT ABSTRACT CSS FILES
//
// The abstracts/ directory gathers all Sass tools and helpers used across the project.
// Every global variable, function, mixin and placeholder should be put in here.
// The rule of thumb for all files within the abstracts/ directory is that it should
// not output a single line of CSS when compiled on its own. These are nothing but Sass helpers.
@import '../../scss/abstracts/breakpoint';
@import '../../scss/abstracts/functions';
@import '../../scss/abstracts/mixins';
@import '../../scss/abstracts/placeholders';
@import '../../scss/abstracts/variables';

/*--------------------------------------------------------------
# components / View Type One
--------------------------------------------------------------*/

:local {
// $gap: 40vw;
$gap: 53.2vw;

.view-type-one {
  display: flex;
  // background: pink;

  & h2 {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-bottom: 1.75rem;
    // background: red;
    opacity: 1;
    transform: translate3d(0, 0, 0);

    // animation: elements--fade-from-bottom $animation-speed--1500 ease;
    // animation-delay: $animation-speed--1500;
    // animation-iteration-count: 1;
    // animation-fill-mode: forwards;
    // transition: width $animation-speed--500 ease;


    @include breakpoint($tablet-m) {
      min-height: none;
    }

    @include breakpoint($laptop-m) {
      min-height: none;
    }

    @include breakpoint($hd) {
      min-height: none;
    }
  }

  & p {
    margin-bottom: 3.4rem;

    opacity: 1;
    transform: translate3d(0, 0, 0);

    // animation: elements--fade-from-bottom $animation-speed--1500 ease;
    // animation-delay: $animation-speed--2500;
    // animation-iteration-count: 1;
    // animation-fill-mode: forwards;
    // transition: width $animation-speed--500 ease;
  }


}



@keyframes elements--fade-from-bottom {
  0% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}



.view-type-one__wrapper {
  position: relative;
  display: flex;
  flex: 1 1 100%;
  flex-direction: column-reverse;
  // background: orange;
  overflow: hidden;

  @include breakpoint($laptop-m) {
    min-height: 100vh;
  }
}


.view-type-one__image-side {
  @include element-fade-effect;

  position: relative;
  flex: 1 1 100%;
  max-height: 46vh;
  min-height: 14rem;
  overflow: hidden;
  pointer-events: none;
  // background-color: greenyellow;

  @include breakpoint($laptop-m) {
    // position: absolute;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: $z-index--minus-1;
    // width: $gap;
    width: $gap * 1.05;
    height: 100vh;
    max-height: none;
    min-height: none;
    opacity: 1;
    transform: translate3d(10%, 0, 0);
    animation: view-type-one__image-side--fade-from-right $animation-speed--500 ease;
    animation-delay: $animation-speed--1000;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    transition: width $animation-speed--500 ease;
  }
}


@keyframes view-type-one__image-side--fade-from-right {
  0% {
    transform: translate3d(10%, 0, 0);
    // opacity: 0;
  }

  100% {
    transform: translate3d(0, 0, 0);
    // opacity: 1;
  }
}



.view-type-one__image-side__heading,
.view-type-one__content-side__heading {
  @include font-lato__28--700;
  z-index: $z-index--3;
}

.view-type-one__image-side__heading {
  position: absolute;
  bottom: 0;
  // margin-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 2rem;
  padding-left: 1rem;
  font-size: 2.6rem; // remove this later.
  color: $color__dirty-white;
  // top: 10rem;
  // background: orange;

  @include breakpoint($laptop-m) {
    display: none;
  }
}

.view-type-one__content-side__heading {
  display: none;

  @include breakpoint($laptop-m) {
    position: absolute;
    // top: 8.7rem;
    top: 0;
    right: -26%;
    display: inline-block;
    width: 45rem;
    max-height: 9.5rem;
    color: $color__spacegrey;
    opacity: 0;
    // opacity: 1;
    // transform: translate3d(20.6%, 0, 0);
    transform: translate3d(26%, 0, 0);

    animation: view-type-one__content-side__heading--fade-from-right $animation-speed--1500 ease;
    animation-delay: $animation-speed--1000;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}

@keyframes view-type-one__content-side__heading--fade-from-right {
  0% {
    opacity: 0;
    transform: translate3d(15.6%, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(26%, 0, 0);
  }
}

.view-type-one__content-side__heading.view-type-one--reverse {
  right: auto;
  left: -20.6%;
  transform: translate3d(-15.6%, 0, 0);

  animation: view-type-one__content-side__heading--fade-from-right--reverse $animation-speed--1500 ease;
  animation-delay: $animation-speed--1000;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

}

@keyframes view-type-one__content-side__heading--fade-from-right--reverse {
  0% {
    opacity: 0;
    transform: translate3d(-15.6%, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(-20.6%, 0, 0);
  }
}


.view-type-one__content-side__heading--space-left {
  padding-left: 21.5%;
}

.view-type-one__image-side__background-gradient {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $z-index--2;
  pointer-events: none;
  background-image: $gradient-home;
  opacity: 0.8;
}

.view-type-one__image-side.view-type-one--reverse {
  top: 0;
  left: 0;

  @include breakpoint($laptop-m) {
    transform: translate3d(-10%, 0, 0);
    animation: view-type-one__image-side--fade-from-left $animation-speed--1500 ease;
    animation-delay: $animation-speed--800;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}


@keyframes view-type-one__image-side--fade-from-left {
  0% {
    transform: translate3d(-10%, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

.view-type-one__image-side > img {
  position: relative;
  z-index: $z-index--1;
  width: 100%;
  height: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0;
  transform: scale3d(1.1, 1.1, 1);
  animation: view-type-one__image-side--zoom-effect $animation-speed--1500 ease;
  animation-delay: $animation-speed--1000;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  transition: filter $animation-speed--500 ease, transform $animation-speed--500 ease;
  object-fit: cover;
  object-position: center;
}


@keyframes view-type-one__image-side--zoom-effect {
  0% {
    transform: scale3d(1.1, 1.1, 1);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}



.view-type-one__image-side > img:hover {
  // filter: blur(10px);
  // transform: scale3d(1.2, 1.2, 1);

  // filter: blur(3px);
  // filter: blur(8px);
  // transform: scale3d(1.1, 1.2, 1);
  // transform: scale3d(1.05, 1.05, 1);
  // transform: scale3d(1.1, 1.1, 1);
}





.view-type-one__content-side {
  flex: 1 1 100%;
  padding-top: 2rem;
  padding-right: 1rem;
  padding-left: 1rem;
  background-color: $color__dirty-white;
  // background-color: yellow;

  @include breakpoint($laptop-m) {
    // position: relative;
    padding-top: 15.2rem;
    padding-right: 7%;
    padding-right: 8.5%; // original spec
    padding-bottom: 1rem;
    padding-left: 2%;
    padding-left: 4%; // original spec
    margin-top: 9rem;
    margin-right: $gap;
    background-color: transparent;
    transform: translateX(0);
    transition: transform $animation-speed--500 ease;
    // background: pink;
  }
}

.view-type-one__content-side:hover {
  @include breakpoint($laptop-m) {
    transform: translateX(2rem);
  }
}

.view-type-one__content-side:hover.view-type-one--reverse {
  @include breakpoint($laptop-m) {
    transform: translateX(-2rem);
  }
}

  .view-type-one__content-side:hover + .view-type-one__image-side {
  @include breakpoint($laptop-m) {
    // width: $gap * 1.1;
    width: $gap;
  }
}


.view-type-one__content-side.view-type-one--reverse {

  @include breakpoint($laptop-m) {
    padding-right: 2%;
    padding-left: 7%;
    padding-right: 4%; // original spec
    padding-left: 8.5%; // original spec
    margin-right: 0;
    margin-left: $gap;
  }

}

}

