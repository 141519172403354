// //////////////////////////////
// Abstracts > MIXINS
//
// What's the difference between mixins and functions?
// Read this: http://thesassway.com/advanced/pure-sass-functions

// //////////////////////////////
// Abstracts > Mixins > Event wrapper
// @author Harry Roberts
// @param {Bool} $self [false] - Whether or not to include current selector
// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts

// EXAMPLE USAGE:
// @include on-event($self: false) {
//   color: $color__black;
// }

@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}

// //////////////////////////////
// Box Shadows

@mixin box-shaddow--one {
  box-shadow: 0 0.23rem 0.45rem 0 $color__black--20;
}


// //////////////////////////////
// Abstracts > Mixins > Typography Mixins
//
// NOTE: READ MORE ABOUT HOW TO CORRECTLY USE MIXINS WITHOUT ARGUMENTS
// 1) !!! https://csswizardry.com/2014/11/when-to-use-extend-when-to-use-a-mixin/ !!!
//


// //////////////////////////////
// Abstracts > MIXINS > GROUP NAME: font-lato__36

@mixin font-lato__36--700 {
  // font-size: 4.2rem;
  font-size: 2.465rem;
  font-weight: 400;
  // line-height: 1.18;
}


// //////////////////////////////
// Abstracts > MIXINS > GROUP NAME: font-lato__28

@mixin font-lato__28--700 { // DONE
  // margin: 1rem 0;
  font-family: $font__secondary;
  font-size: 4.2rem;
  font-weight: 400;
  line-height: 1.12;
  letter-spacing: 0.1rem;
  // line-height: 1.18;
  // color: $color__sharp-blue;
}


// //////////////////////////////
// Abstracts > MIXINS > GROUP NAME: font-lato__25

@mixin font-lato__25--700 {
  // margin: 1rem 0;
  // font-family: $font__main;
  // font-size: 1.8rem;
  font-weight: 700;
  // line-height: 1.18;
  // color: $color__sharp-blue;
}


// //////////////////////////////
// Abstracts > MIXINS > GROUP NAME: font-lato__24

@mixin font-lato__24--700 {
  // margin: 1rem 0;
  // font-family: $font__main;
  // font-size: 1.8rem;
  font-weight: 700;
  // line-height: 1.18;
  // color: $color__sharp-blue;
}


// //////////////////////////////
// Abstracts > MIXINS > GROUP NAME: font-lato__22


@mixin font-lato__22--400 { // DONE
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.4;
}

@mixin font-lato__22--400-secondary { // DONE
  font-family: $font__secondary;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.4;
}

@mixin font-lato__22--700 { // DONE
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.4;
}


// //////////////////////////////
// Abstracts > MIXINS > GROUP NAME: font-lato__18

@mixin font-lato__18--700 {
  // margin: 1rem 0;
  // font-family: $font__main;
  // font-size: 1.8rem;
  font-weight: 700;
  // line-height: 1.18;
  // color: $color__sharp-blue;
}

// //////////////////////////////
// Abstracts > MIXINS > GROUP NAME: font-lato__16

// [BASE FONT]
@mixin font-lato__16--400 {
  font-weight: 400;
  // line-height: 1.4;
}

@mixin font-lato__16--700 {
  font-weight: 700;
}

@mixin font-lato__16--900 {
  font-weight: 900;
}


// //////////////////////////////
// Abstracts > MIXINS > GROUP NAME: font-lato__14 !!! DONE

@mixin font-lato__14--700 {
  // margin: 1rem 0;
  // font-family: $font__main;
  // font-size: 1.8rem;
  font-weight: 700;
  // line-height: 1.18;
  // color: $color__sharp-blue;
}


// //////////////////////////////
// Abstracts > MIXINS > Background Image Fill Animation

@mixin background-image-fill-animaiton($background-image: $gradient-standard--45deg, $animation-speed: $animation-speed--500) {
  background-image: $background-image;
  animation: background-image-fill-animaiton $animation-speed ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}


@mixin background-image-fill-animaiton--dark($background-image: $gradient-standard--spacegrey, $animation-speed: $animation-speed--500) {
  background-image: $background-image;
  animation: background-image-fill-animaiton $animation-speed ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes background-image-fill-animaiton {
  0% {
    background-size: 0% 100%;
    background-position-x: 0%;
    background-position-y: 100%;
  }

  15% {
    background-size: 25% 100%;
    background-position-x: 0%;
    background-position-y: 100%;
  }

  15% {
    background-size: 25% 100%;
    background-position-x: 0%;
    background-position-y: 100%;
  }

  45% {
    background-size: 25% 100%;
    background-position-x: 100%;
    background-position-y: 100%;
  }

  100% {
    background-size: 100% 100%;
    background-position-x: 100%;
    background-position-y: 100%;
  }
}


// //////////////////////////////
// Abstracts > MIXINS > Background Image Fill Animation From Down to Up


@mixin background-image-fill-animaiton--from-down-to-up($background-image: $gradient-standard--45deg, $animation-speed: $animation-speed--500) {
  background-image: $background-image;
  animation: background-image-fill-animaiton--from-down-to-up $animation-speed ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes background-image-fill-animaiton--from-down-to-up {
  0% {
    background-size: 100% 10%;
    background-position-x: 100%;
    background-position-y: 100%;
  }

  75% {
    background-size: 100% 100%;
    background-position-x: 100%;
    background-position-y: 100%;
  }

  100% {
    background-size: 100% 100%;
    background-position-x: 100%;
    background-position-y: 100%;
  }
}


// //////////////////////////////
// Abstracts > MIXINS > Background Image Moving Animation

@mixin background-image-moving-animation($background-image: $gradient-standard--45deg, $animation-speed: $animation-speed--2000) {
  background-image: $background-image;
  animation: background-image-moving-animation $animation-speed ease-in-out;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}

@keyframes background-image-moving-animation {

  0% {
    background-size: 100% 100%;
    background-position-x: 100%;
    background-position-y: 100%;
  }

  50% {
    background-size: 200% 150%;
  }

  100% {
    background-size: 100% 100%;
    background-position-x: 100%;
    background-position-y: 100%;
  }
}



// //////////////////////////////
// Abstracts > MIXINS > Element Fade Animations


@mixin element-fade-effect($animation-speed: $animation-speed--500) {
  // opacity: 0;
  animation: elment-fadein $animation-speed
    cubic-bezier(0.165, 0.84, 0.44, 1);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes elment-fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
