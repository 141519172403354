// //////////////////////////////
// IMPORT ABSTRACT CSS FILES
//
// The abstracts/ directory gathers all Sass tools and helpers used across the project.
// Every global variable, function, mixin and placeholder should be put in here.
// The rule of thumb for all files within the abstracts/ directory is that it should
// not output a single line of CSS when compiled on its own. These are nothing but Sass helpers.
@import '../../scss/abstracts/breakpoint';
@import '../../scss/abstracts/functions';
@import '../../scss/abstracts/mixins';
@import '../../scss/abstracts/placeholders';
@import '../../scss/abstracts/variables';

/*--------------------------------------------------------------
# components / Header
--------------------------------------------------------------*/


:local {
  .header--type-1 {
    // position: fixed;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: $z-index--4;
    // min-height: 6.8rem;
  }


  // If backdrop-filter is NOT supported then apply
  // a slightly transparent background color as a fallback
  .backdrop-blur {
    background-color: $color__dirty-white--75;
    // background-color: orange;
  }

  // if backdrop-filter is supported then apply
  // READ: https://developer.mozilla.org/en-US/docs/Web/CSS/@supports
  @supports ((-webkit-backdrop-filter: blur(0.25em)) or (backdrop-filter: blur(0.25em))) {

    // sass-lint:disable-line no-vendor-prefixes
    .backdrop-blur {
      background-color: $color__dirty-white--50;
      -webkit-backdrop-filter: blur(0.25em); // sass-lint:disable-line no-vendor-prefixes
      backdrop-filter: blur(0.25em);
    }
  }


  // .header--type-1 {
  // }

  // .header--type-2 {

  // }

  .header__wrapper {
    position: relative;
    display: flex;
    min-height: 4.5rem;
    // padding-right: 1rem;
    // padding-left: 1rem;
    // margin-top: 0.3rem;
  }


  .header__logo {
    display: flex;
    align-items: center;
    flex: 4 4 100%;
    // justify-content: center;
    justify-content: flex-start;
    padding-left: 3.3rem;
    // background: red;
  }

  .header__logo svg {
    width: auto;
    height: 3rem;
    fill: $color__spacegrey;

    &:visited {
      fill: $color__spacegrey;
    }

    &:hover,
    &:focus,
    &:active {
      fill: $color__spacegrey;
    }
  }

  .header__right-side {
    display: flex;
    align-items: center;
    flex: 1 1 100%;
    justify-content: flex-end;
    // background: green;

    & > button {
      // margin-right: 2.7rem;
      margin-right: 4.5%;
    }
  }



  .nav {
    @include font-lato__22--400-secondary;

    display: none;

    @include breakpoint($global-navigation-breakpoint) {
      display: flex;
      height: 100%;
      padding: 0 0.55rem;
    }
  }

  .nav__first-level {
    display: flex;
    height: 100%;
    padding-left: 0;
    margin: 0;
    list-style: none;
    // background: darkorange;
  }

  .nav__first-level__button {
    // background: salmon;
    display: flex;
    overflow: hidden;
    text-decoration: none;

    // &:last-child {
    //   margin-right: 2.1rem;
    // }
  }


  .nav__first-level__button--wrapper {
    position: relative;
    display: flex;
  }




  .nav__first-level__button:focus-within a {
    outline: none;
  }

  .nav__second-level {
    position: absolute;
    right: 0;
    bottom: -39.4%;
    left: 0;
    display: flex;
    justify-content: flex-end;
    min-height: 3rem;
    // padding-right: 17%;
    // padding-right: 25%;
    // padding-right: 36vw !important; // fix this
    padding-right: 11vw !important; // fix this
    pointer-events: none;
    background-color: $color__dirty-white--50;
    opacity: 0;
    visibility: hidden;
    transform: translate3d(0, 39.4%, 0);
    transition: opacity $animation-speed--500 ease;
  }


  .nav__first-level__button:hover > .nav__second-level {
    pointer-events: all;
  }

  .nav__first-level__button:focus > .nav__second-level,
  .nav__first-level__button:hover > .nav__second-level,
  .nav__first-level__button:focus-within > .nav__second-level,
  .nav__first-level__button:focus + .nav__second-level,
  .nav__second-level:hover,
  .nav__second-level:focus {
    opacity: 1;
    visibility: visible;
  }

  .nav__second-level__button {
    display: flex;
    // align-items: center;

    &::before {
      content: '';
    }
  }


    .nav__first-level__button a {

    display: flex;
    align-items: center;
    padding: 0 1.2rem;
    color: $color__dirty-white;
    // background: darkcyan;

    &:link {
      color: $color__dirty-white;
      text-decoration: none;
    }

    &:visited {
      color: $color__dirty-white;
      text-decoration: none;
    }

    &:hover,
    &:focus,
    &:active {
      @include background-image-fill-animaiton--from-down-to-up($gradient-standard--270deg, $animation-speed--250);

      color: $color__dirty-white;
      text-decoration: none;
      border: none;
      outline: 0;
    }
  }

  .nav__button--dark a {
    // color: $color__spacegrey;

    &:link {
      color: $color__spacegrey;
    }

    &:visited {
      color: $color__spacegrey;
    }

    &:hover,
    &:focus,
    &:active {
      color: $color__dirty-white;
    }
  }

}
