// //////////////////////////////
// IMPORT ABSTRACT CSS FILES
//
// The abstracts/ directory gathers all Sass tools and helpers used across the project.
// Every global variable, function, mixin and placeholder should be put in here.
// The rule of thumb for all files within the abstracts/ directory is that it should
// not output a single line of CSS when compiled on its own. These are nothing but Sass helpers.
@import './breakpoint';
@import './functions';
@import './mixins';
//
//
// //////////////////////////////
// Abstracts > VARIABLES

// //////////////////////////////
// Breakpoints

$mobile-m: 20rem;
$mobile-l: 23rem;
$tablet: 26rem; // <768px .col-xs-
$tablet-m: 35rem; // 560px to 767px .col-xs-two-
$laptop: 48rem; //768px to 991px .col-sm-
$laptop-m: 63rem; //992px to 1199px .col-md-
$laptop-l: 75rem; //1200px .col-lg-
$hd: 80.7rem;
$hd-m: 90.7rem;
$hd-l: 107rem;
$four-k: 159rem;

// $global-navigation-breakpoint: 1036px;
$global-navigation-breakpoint: 1060px;

// //////////////////////////////
// Abstracts > VARIABLES > FONT STACKS

/// Regular font family
$font__main: ('Open Sans', sans-serif) !default;

/// Secondary font family
$font__secondary: ('Ropa Sans', sans-serif) !default;

/// This is the fall-back font family if
/// Web Fonts fail to load
$font__fallback: (sans-serif) !default;

/// Code (monospace) font family
$font__code: (Monaco, Consolas, 'Andale Mono', 'DejaVu Sans Mono', monospace)
  !default;


// //////////////////////////////
// Z-Index's

$z-index--minus-1: -1;
$z-index--1: 1;
$z-index--2: 2;
$z-index--3: 3;
$z-index--4: 4;
$z-index--5: 5;
$z-index--6: 6;
$z-index--7: 7;
$z-index--8: 8;
$z-index--9: 9;
$z-index--10: 10;


// //////////////////////////////
// Abstracts > VARIABLES > Colors

// $color__creative-red
$color__creative-red: rgba(232, 61, 75, 1);
$color__creative-red--01: rgba(232, 61, 75, 0.01);

// $color__sharp-blue
$color__sharp-blue: rgba(70, 60, 145, 1);
$color__sharp-blue--44: rgba(70, 60, 145, 0.44);
$color__sharp-blue--30: rgba(70, 60, 145, 0.3);
$color__sharp-blue--25: rgba(70, 60, 145, 0.25);

// $color__dirty-white
// $color__dirty-white: rgba(246, 243, 247, 1);
$color__dirty-white: rgba(246, 243, 247, 1);
$color__dirty-white--80: rgba(246, 243, 247, 0.8);
$color__dirty-white--75: rgba(246, 243, 247, 0.75);
$color__dirty-white--50: rgba(246, 243, 247, 0.5);

// $color__spacegrey
$color__spacegrey: rgba(31, 30, 33, 1);
$color__spacegrey--80: rgba(31, 30, 33, 0.8);

// $color__light-grey
$color__light-grey: rgba(240, 240, 240, 1);

// $color__lighter-grey
$color__lighter-grey: rgba(78, 94, 110, 1);

// $color__purpley-grey
$color__purpley-grey: #9a989e;

// $color__white
$color__white: rgba(255, 255, 255, 1);

// $color__black
$color__black: rgba(0, 0, 0, 1);


// //////////////////////////////
// Abstracts > Background images
// READ: https://codepen.io/P1N2O/pen/pyBNzX
// READ: https://css-tricks.com/the-state-of-changing-gradients-with-css-transitions-and-animations/
// READ: https://www.gradient-animator.com/


// $gradient-standard
$gradient-standard: linear-gradient(0, $color__creative-red 0%, $color__sharp-blue 100%);
$gradient-standard--45deg: linear-gradient(-45deg, $color__creative-red 0%, $color__sharp-blue 100%);
$gradient-standard--270deg: linear-gradient(-270deg, $color__creative-red 0%, $color__sharp-blue 100%);
$gradient-standard--reverse: linear-gradient(0, $color__creative-red 100%, $color__sharp-blue 0%);


$gradient-standard--spacegrey: linear-gradient(0, $color__spacegrey, $color__spacegrey);

// $gradient-career
$gradient-career: linear-gradient(117deg, $color__creative-red 0%, $color__sharp-blue--25 75%, $color__sharp-blue 100%);

// $gradient-home
$gradient-home: linear-gradient(60deg, $color__creative-red 0%, $color__sharp-blue 88%);

// $gradient-contact
$gradient-contact: linear-gradient(39deg, $color__creative-red 0%, $color__sharp-blue--30 70%, $color__sharp-blue 100%);

// $gradient-references
$gradient-references: linear-gradient(90deg, $color__creative-red 0%, $color__sharp-blue 79%);

// $gradient-agency
$gradient-agency: linear-gradient(-180deg, $color__sharp-blue 16%, $color__creative-red 100%);

// $gradient-ittaskforce
$gradient-ittaskforce: linear-gradient(53deg, $color__creative-red--01 0%, $color__sharp-blue--44 70%, $color__sharp-blue 100%);

// $gradient-services
$gradient-services: linear-gradient(-180deg, $color__sharp-blue 16%, $color__creative-red 100%);

// $gradient-impressum
$gradient-impressum: linear-gradient(269deg, $color__dirty-white--80, $color__creative-red 100%);




// //////////////////////////////
// Abstracts > VARIABLES > Animationa Speeds

$animation-speed--3000: 3000ms;
$animation-speed--2500: 2500ms;
$animation-speed--2000: 2000ms;
$animation-speed--1500: 1500ms;
$animation-speed--1000: 1000ms;
$animation-speed--800: 800ms;
$animation-speed--500: 500ms;
$animation-speed--400: 400ms;
$animation-speed--250: 250ms;
$animation-speed--200: 200ms;


// //////////////////////////////
// Abstracts > VARIABLES > Root Varaible Overrides

// // Description: Controls the page font inherited by all
// // elements (default: sans-serif)
// $root--font-family: <enterValue>;
$root--font-family: $font__main;

// // Description: Controls the page text line height
// // inherited by all elements (default: 1.58)
// $root--line-height: <enterValue>;
$root--line-height: 1.51;

// // Description: Controls the page text color inherited
// // by all elements (default: #000000
// $root--color: <enterValue>;
$root--color: $color__spacegrey;

// // Description: Controls the page background color (default: #ffffff)
// // Specify the root styles of the document
// $root--background-color: <enterValue>;
$root--background-color: $color__dirty-white;


// //////////////////////////////
// Abstracts > VARIABLES > Monospace Varaible Overrides

// // Description: Controls the font used by markup elements
// // (code, kbd, pre, samp) (default: monospace)
// $font__code: <enterValue>;

// // Description: Controls the font size used by markup elements
// // (code, kbd, pre, samp) (default: 1em)
// $font__code--size: <enterValue>;


// //////////////////////////////
// Abstracts > VARIABLES > Selection Varaible Overrides

// // Description: Controls the background color of selected
// // text (default: #B3D4FC;)
// $selection--background-color: <enterValue>;
$selection--background-color: $color__creative-red;

// // Description: Controls the text color of selected text
// // (default: #FFFFFF)
// $selection--color: <enterValue>;
$selection--color: $color__dirty-white;


// //////////////////////////////
// Abstracts > VARIABLES > Small & Big font Varaible Override

// // Description: Controls the text size of small elements (default: 83.3333%)
// $font__small--size: <enterValue>;

// // Description: Controls the text size of big elements (default: 125%)
// $font__big--size: <enterValue>;
