// //////////////////////////////
// IMPORT ABSTRACT CSS FILES
//
// The abstracts/ directory gathers all Sass tools and helpers used across the project.
// Every global variable, function, mixin and placeholder should be put in here.
// The rule of thumb for all files within the abstracts/ directory is that it should
// not output a single line of CSS when compiled on its own. These are nothing but Sass helpers.
@import '../abstracts/breakpoint';
@import '../abstracts/functions';
@import '../abstracts/mixins';
@import '../abstracts/placeholders';
@import '../abstracts/variables';
//
//
/* ----------------------------------------------
** Base > Grid
* -------------------------------------------- */

/* ----------------------------------------------
** Base > Grid > Container
* -------------------------------------------- */

.container,
.container--with-padding {
  max-width: $hd-l;
  padding-right: 5%;
  padding-left: 5%;
  margin: 0 auto;
}

.container--no-padding {
  padding: 0;
}


/* ----------------------------------------------
** Base > Grid > Grid Wrappers
* -------------------------------------------- */

.grid--wrapper {
  display: flex;
  flex-wrap: wrap;
}

.grid--wrapper--column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}


/* ----------------------------------------------
** Base > Grid > Grid Columns
* -------------------------------------------- */

.grid__column--28 {
  flex: 1 1 100%;
  @include breakpoint($tablet-m) {
    // flex: 0 0 auto;
    // margin-right: 70%; // remove this and make a 70% margin-right thing
    flex: 1 1 28%;
  }
}

.grid__column--29 {
  flex: 1 1 100%;
  @include breakpoint($tablet-m) {
    // flex: 0 0 auto;
    // margin-right: 70%; // remove this and make a 70% margin-right thing
    flex: 1 1 29%;
  }
}

.grid__column--31 {
  flex: 1 1 100%;
  @include breakpoint($tablet-m) {
    // flex: 0 0 auto;
    // margin-right: 70%; // remove this and make a 70% margin-right thing
    flex: 1 1 31%;
  }
}

  .grid__column--43 {
  flex: 1 1 100%;
  @include breakpoint($tablet-m) {
    // flex: 0 0 auto;
    // margin-right: 70%; // remove this and make a 70% margin-right thing
    flex: 1 1 43%;
  }
}

.grid__column--48 {
  flex: 1 1 100%;
  @include breakpoint($tablet-m) {
    flex: 1 1 48%;
  }
}

.grid__column--53 {
  flex: 1 1 100%;
  @include breakpoint($tablet-m) {
    flex: 1 1 53%;
  }
}

.grid__column--65 {
  flex: 1 1 100%;
  @include breakpoint($tablet-m) {
    flex: 1 1 65%;
  } // @include breakpoint($laptop) {
  //   flex: 0 0 65%;
  // }
}

.grid__column--100 {
  flex: 1 1 100%;
}

.grid__column--28,
.grid__column--29,
.grid__column--31,
.grid__column--36,
.grid__column--48,
.grid__column--65,
.grid__column--100 {
  margin-bottom: 0.8rem;
}


/* ----------------------------------------------
** Base > Grid > Custom Margins
* -------------------------------------------- */

.grid__left-margin--1 {
  @include breakpoint($tablet-m) {
    margin-left: 1%;
  }
}

.grid__right-margin--1 {
  @include breakpoint($tablet-m) {
    margin-right: 1%;
  }
}


/* ----------------------------------------------
** Base > Grid > Custom Margins > 2% Margins
* -------------------------------------------- */

.grid__left-margin--2 {
  @include breakpoint($tablet-m) {
    margin-left: 2%;
  }
}

.grid__right-margin--2 {
  @include breakpoint($tablet-m) {
    margin-right: 2%;
  }
}


/* ----------------------------------------------
** Base > Grid > Custom Margins > 3% Margins
* -------------------------------------------- */

.grid__left-margin--3 {
  @include breakpoint($tablet-m) {
    margin-left: 3%;
  }
}

.grid__right-margin--3 {
  @include breakpoint($tablet-m) {
    margin-right: 3%;
  }
}


/* ----------------------------------------------
** Base > Grid > Custom Margins > 69% Margins
* -------------------------------------------- */

.grid__left-margin--69 {
  @include breakpoint($tablet-m) {
    margin-left: 69%;
  }
}

.grid__right-margin--69 {
  @include breakpoint($tablet-m) {
    margin-right: 69%;
  }
}


/* ----------------------------------------------
** Base > Grid > Custom Margins > 72% Margins
* -------------------------------------------- */

.grid__left-margin--72 {
  @include breakpoint($tablet-m) {
    margin-left: 72%;
  }
}

.grid__right-margin--72 {
  @include breakpoint($tablet-m) {
    margin-right: 72%;
  }
}
