// //////////////////////////////
// IMPORT ABSTRACT CSS FILES
//
// The abstracts/ directory gathers all Sass tools and helpers used across the project.
// Every global variable, function, mixin and placeholder should be put in here.
// The rule of thumb for all files within the abstracts/ directory is that it should
// not output a single line of CSS when compiled on its own. These are nothing but Sass helpers.
@import '../../scss/abstracts/breakpoint';
@import '../../scss/abstracts/functions';
@import '../../scss/abstracts/mixins';
@import '../../scss/abstracts/placeholders';
@import '../../scss/abstracts/variables';

/*--------------------------------------------------------------
# components / Footer
--------------------------------------------------------------*/


:local {
    .footer-hacker {
      padding-top: 1.5rem;
      padding-bottom: 3rem;
      margin-top: 10rem;
      margin-bottom: 5rem;
      border-top: 1px solid $color__spacegrey--80;

      @include breakpoint($global-navigation-breakpoint) {
        margin-bottom: 2rem;
      }
    }


}
