/* ----------------------------------------------
** Base > Fonts
* -------------------------------------------- */
// READ THIS: https://css-tricks.com/snippets/css/using-font-face/
// READ regarding naming of fonts: http://typekit.github.io/fvd/
// READ Using unicode-range: http://caniuse.com/#search=unicode-range
// READ support for woff: http://caniuse.com/#search=woff
// !! GET FONTS FROM !! http://google-webfonts-helper.herokuapp.com/fonts
//
// Woff2: Chrome 26+, Opera 23+, Firefox 39+
// Woff: Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+


// /* 'lato:n3:latin' */
// @font-face {
//   font-family: 'Lato';
//   font-style: normal;
//   font-weight: 300;
//   src: local('Lato Light'), local('Lato-Light'),
//        url('./fonts/latin/lato-v14-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
//        url('./fonts/latin/lato-v14-latin-300.woff') format('woff'), /* Modern Browsers */
//        url('./fonts/latin/lato-v14-latin-300.ttf') format('truetype'); /* Safari, Android, iOS */
//        unicode-range: U+0020-00ff;
// }


/* ropa-sans-regular - latin */
@font-face {
  font-family: 'Ropa Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Ropa Sans Regular'), local('RopaSans-Regular'),
       url('./fonts/latin/ropa-sans-v7-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/latin/ropa-sans-v7-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
       unicode-range: U+0020-00ff;
}


/* ropa-sans-italic - latin */
@font-face {
  font-family: 'Ropa Sans';
  font-style: italic;
  font-weight: 400;
  src: local('Ropa Sans Italic'), local('RopaSans-Italic'),
       url('./fonts/latin/ropa-sans-v7-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/latin/ropa-sans-v7-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
       unicode-range: U+0020-00ff;
}


/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
       url('./fonts/latin/open-sans-v15-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/latin/open-sans-v15-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
       unicode-range: U+0020-00ff;
}


/* open-sans-800 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
       url('./fonts/latin/open-sans-v15-latin-800.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/latin/open-sans-v15-latin-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
       unicode-range: U+0020-00ff;
}
