// //////////////////////////////
// IMPORT ABSTRACT CSS FILES
//
// The abstracts/ directory gathers all Sass tools and helpers used across the project.
// Every global variable, function, mixin and placeholder should be put in here.
// The rule of thumb for all files within the abstracts/ directory is that it should
// not output a single line of CSS when compiled on its own. These are nothing but Sass helpers.
@import './abstracts/breakpoint';
@import './abstracts/functions';
@import './abstracts/mixins';
@import './abstracts/placeholders';
@import './abstracts/variables';
//
//
// ----------------------------------------------
//  Shame File
// ----------------------------------------------
// The idea of shame file is that you have a totally new stylesheet reserved just
// for your hacky code. The code you have to write to get the release out on time,
// but the code that makes you ashamed.
// READ: http://csswizardry.com/2013/04/shame-css/
//


.special-p {
  margin-bottom: 0.5rem !important;
}


.logo-editorz {
  width: 6rem;
  height: auto;
  margin-bottom: 1rem;
}
