// //////////////////////////////
// IMPORT ABSTRACT CSS FILES
//
// The abstracts/ directory gathers all Sass tools and helpers used across the project.
// Every global variable, function, mixin and placeholder should be put in here.
// The rule of thumb for all files within the abstracts/ directory is that it should
// not output a single line of CSS when compiled on its own. These are nothing but Sass helpers.
@import '../../scss/abstracts/breakpoint';
@import '../../scss/abstracts/functions';
@import '../../scss/abstracts/mixins';
@import '../../scss/abstracts/placeholders';
@import '../../scss/abstracts/variables';

/*--------------------------------------------------------------
# components / Jumbotron
--------------------------------------------------------------*/



:local {
    .jumbotron {
      position: relative;
    }

    .jumbotron__wrapper {
      width: 100%;
      // min-height: 70vh;
      // background: green;
    }

    // //////////////////////////////
    // Jumbotron > Contenet

    .jumbotron__content {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      min-height: 50vh;
      padding-top: 9rem;
      padding-bottom: 5rem;
      // background: palevioletred;
    }

    .jumbotron__content__overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: $z-index--1;
      background: $color__dirty-white--80;

    }

    .jumbotron__content__heading {
      z-index: $z-index--2;
      margin-bottom: 3.5rem;
      text-align: center;
      // background: purple;

      // @include breakpoint($laptop-m) {
        opacity: 0;
        transform: translate3d(0, 0, 0);
        animation: elements--fade-from-bottom $animation-speed--400 ease;
        animation-delay: $animation-speed--400;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        transition: width $animation-speed--500 ease;
      // }
    }

    @keyframes elements--fade-from-bottom {
      0% {
        opacity: 0;
        transform: translate3d(0, 0, 0);
      }

      100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }

    // //////////////////////////////
    // Jumbotron > Contenet > Buttons

    .jumbotron__content__buttons {
      z-index: $z-index--2;
      display: flex;
      align-content: center;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      max-width: 40rem;
      // background: greenyellow;
    }

    .jumbotron__content__buttons button,
    .jumbotron__content__buttons a {
      display: flex;

      align-content: center;
      align-items: center;
      // flex-direction: column;
      flex: 1 1 18rem;
      justify-content: center;
      /* text-align: left; */

      // align-items: center;
      // flex: 1 1 15rem;
      width: 12rem;
      // height: 10rem;
      padding: 0.4rem;
      margin-right: 1rem;
      margin-bottom: 1rem;
      margin-left: 1rem;
      color: $color__dirty-white;
      cursor: pointer;
      background: $color__sharp-blue;
      background-repeat: no-repeat;
      border-radius: 0.2rem;
      transition: background-color $animation-speed--250 ease-in;

      @include breakpoint($tablet-m) {
        flex: 1 1 15rem;
        margin-left: 0;
      }


      &:visited {
        color: $color__dirty-white;
        text-decoration: none;
      }

      &:hover,
      &:focus,
      &:active {
        // @include background-image-fill-animaiton;
        // @include background-image-fill-animaiton--dark;
        // @include background-image-fill-animaiton--from-down-to-up;
        @include background-image-moving-animation;

        color: $color__dirty-white;
        text-decoration: none;
        background-color: $color__spacegrey;
        outline: 0;
      }

    }

    // .jumbotron__content__buttons button:nth-child(2n+1),
    // .jumbotron__content__buttons a:nth-child(2n+1) {
    //   margin-right: 1rem;
    //   @include breakpoint($tablet-m) {
    //     margin-right: 1rem;
    //   }
    //   // @include breakpoint($tablet-m) {
    //   // }
    // }

    .content__buttons__text {
      display: flex;
      flex: 1 1 100%;
      // align-self: flex-start;
    }

    .content__buttons__icons {
      display: flex;
      // align-self: flex-end;
      justify-content: center;
      width: 4rem;
      margin-right: 0.5rem;
      color: $color__sharp-blue;
      background-color: transparent;
      border-radius: 100%;
    }

    .content__buttons__icons svg {
      width: 4rem;
      height: 4rem;
      padding: 1.2rem;
      fill: $color__dirty-white !important;
    }


    .jumbotron__content__buttons button:nth-child(2n+2),
    .jumbotron__content__buttons a:nth-child(2n+2) {
      @include breakpoint($tablet-m) {
        margin-right: 0;
      }
    }

    // //////////////////////////////
    // Jumbotron > Contenet > Slider Buttons

    .jumbotron__content__slider-buttons {
      // background: firebrick;
      z-index: $z-index--2;
      border-radius: 100%;
    }
}

