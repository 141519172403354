// //////////////////////////////
// IMPORT ABSTRACT CSS FILES
//
// The abstracts/ directory gathers all Sass tools and helpers used across the project.
// Every global variable, function, mixin and placeholder should be put in here.
// The rule of thumb for all files within the abstracts/ directory is that it should
// not output a single line of CSS when compiled on its own. These are nothing but Sass helpers.
@import '../abstracts/breakpoint';
@import '../abstracts/functions';
@import '../abstracts/mixins';
@import '../abstracts/placeholders';
@import '../abstracts/variables';
//
//
/* ----------------------------------------------
** Base > Base
* -------------------------------------------- */

/* ----------------------------------------------
** Base > Base > Webkit User Agent Reset
* -------------------------------------------- */
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
small,
big,
a,
hr,
button {
  align-items: unset;
  padding: 0;
  margin: 0; // This is some default Webkit user agent styling. Need to explicitly target these to prevent them from messing with page elements.
  // See: http://stackoverflow.com/questions/17902652/paragraph-rendering-difference-in-chrome-firefox
  -webkit-margin-before: 0; // sass-lint:disable-line no-vendor-prefixes
  // sass-lint:disable-next-line no-vendor-prefixes
  -webkit-margin-after: 0; // sass-lint:disable-line no-vendor-prefixes
  -webkit-margin-start: 0; // sass-lint:disable-line no-vendor-prefixes
  -webkit-margin-end: 0; // sass-lint:disable-line no-vendor-prefixes
}

ul,
.ul--list-style-type-none {
  -webkit-padding-start: 0; // sass-lint:disable-line no-vendor-prefixes property-sort-order
  -webkit-margin-before: 0; // sass-lint:disable-line no-vendor-prefixes property-sort-order
  -webkit-margin-after: 0; // sass-lint:disable-line no-vendor-prefixes property-sort-order
  -webkit-margin-start: 0; // sass-lint:disable-line no-vendor-prefixes property-sort-order
  -webkit-margin-end: 0; // sass-lint:disable-line no-vendor-prefixes property-sort-order
  padding: 0;
  margin: 1rem 0;
  text-align: left;

  & li {
    margin: 0;
    list-style-position: inside;
  } //& li

  ///this is targeting the list within the list
  & li ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  } ///this is targeting the list within the list

  & li ul li {
    padding: 0;
    margin: 0;
    list-style-type: none;
  } ///this is targeting the list within the list

  & li ul li::before {
    content: '- \0000a0';
  } //& li::before
}

.ul--list-style-type-none {
  & li {
    list-style-type: none;
  } //& li
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: initial; // sass-lint:disable-line no-vendor-prefixes
}


/* ----------------------------------------------
** Base > Base > Root Varaible Overrides
* -------------------------------------------- */

html {
  font-family: if(
    variable-exists(root--font-family),
    $root--font-family,
    sans-serif
  );

  // READ:
  // (1) https://www.smashingmagazine.com/2016/05/fluid-typography/
  // (2) http://zellwk.com/blog/viewport-based-typography/
  // FORMULA E.g= 14px (base font size) + ((1000px (actual screen width) * 0.7vw (scaling rate) > 100) = 21px (current displayed font size))

  // Works on IE:
  // font-size: calc(0.875em + 0.27vw);
  font-size: calc(0.8em + 0.27vw);;
  // font-size: 16px; // comment this out in production (only for development)

  // // THIS IS BUGGY, DONT USE, IE SEEMS TO BE USING THIS VALUE AND IT BREAKS THE DESIGN:
  // font-size: -webkit-calc(87.5% + 0.27vw); // sass-lint:disable-line no-duplicate-properties, no-vendor-prefixes
  // // Safari Fix

  // Unitless line heights are recommended due to the fact that child elements will inherit the raw number value, rather than the computed value. With this, child elements can compute their line heights based on their computed font size, rather than inheriting an arbitrary value from a parent that is more likely to need overriding. Unitless line-height = unitless line-height * the element's font size = line-height in px. (https://developer.mozilla.org/en-US/docs/Web/CSS/line-height).
  line-height: if(variable-exists(root--line-height), $root--line-height, 1.58);

  letter-spacing: 0.003em;
  // letter-spacing: 0;

  color: if(variable-exists(root--color), $root--color, #000000);

  // https://developer.mozilla.org/en-US/docs/Web/CSS/text-rendering
  text-rendering: optimizeLegibility;

  background-color: if(
    variable-exists(root--background-color),
    $root--background-color,
    #FFFFFF
  );

  -webkit-font-smoothing: antialiased; // sass-lint:disable-line no-vendor-prefixes
  -moz-osx-font-smoothing: grayscale; // sass-lint:disable-line no-vendor-prefixes

  font-variant-ligatures: common-ligatures;
}


cite,
em,
i {
  font-style: italic;
}

blockquote {
  position: relative;
  padding: 0.2rem 0.5rem 0.1rem 2rem;
  margin: 0.5rem 0 1rem;
  border-left: 0.3rem solid $color__black;

  &::before {
    position: absolute;
    top: 0;
    left: 0.3rem;
    padding: 0;
    margin: 0;
    font-size: 2.358rem;
    content: '“';
  }
}

address {
  margin: 0 0 1.5rem;
}

pre {
  max-width: 100%;
  padding: 1.6rem;
  margin-bottom: 1.6rem;
  overflow: auto;
  color: $color__white;
  background: $color__black;
}

code,
kbd,
tt,
var {
  font-family: $font__code;
}

abbr,
acronym {
  cursor: help;
  border-bottom: 1px dotted #333333;
}

mark,
ins {
  color: if(variable-exists(selection--color), $selection--color, #FFFFFF);
  text-decoration: none;
  background-color: if(
    variable-exists(selection--background-color),
    $selection--background-color,
    #B3D4FC
  );
}

hr {
  margin: 0.5rem 0;
  border: 0.05rem solid $color__dirty-white;
}


/* ----------------------------------------------
** Base > Base > Monospace Varaible Overrides
* -------------------------------------------- */
code,
kbd,
pre,
samp {
  font-family: if(variable-exists(font__code), $font__code, monospace);
  font-size: if(variable-exists(font__code--size), $font__code--size, 1em);
}


/* ----------------------------------------------
** Base > Base > Selection Varaible Overrides
* -------------------------------------------- */
::selection {
  color: if(variable-exists(selection--color), $selection--color, #FFFFFF);
  background-color: if(
    variable-exists(selection--background-color),
    $selection--background-color,
    #B3D4FC
  );
}


/* ----------------------------------------------
** Base > Base > Global <a> tags
* -------------------------------------------- */

a {
  @include font-lato__16--400;

  padding: 0;
  margin: 0;
  color: $color__sharp-blue;
  text-decoration: none;
  transition: color $animation-speed--250 ease-in;
  fill: $color__sharp-blue;

  &:visited {
    color: $color__sharp-blue;
    text-decoration: none;
    // fill: $color__sharp-blue;
  }

  &:hover,
  &:focus,
  &:active {
    color: $color__sharp-blue;
    text-decoration: underline;
    // text-decoration: none;
    outline: 0;
    // fill: $color__sharp-blue;
  }
}

/* Remove or Add border when an element is in focus */
*:focus {
  outline: 0.02rem dotted $color__spacegrey;
  /* outline: 0.05rem dotted transparent; */
}


/* ----------------------------------------------
** Base > Base > Media
* -------------------------------------------- */

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
  max-width: 100%;
}


/* ----------------------------------------------
** Base > Base > Image Scaling
* -------------------------------------------- */

img {
  // width: 100%;
  height: auto; // make sure images are scaled correctly.
  max-width: 100%; // Adhere to container width.
  padding: 0;
  margin: 0;
}


/* ----------------------------------------------
** Base > Base > Two Columns
* -------------------------------------------- */

// .two-columns {
  // columns: 15.4rem 2;
  // columns: 14.4rem 2;
  // column-gap: 2rem;
// }

/* ----------------------------------------------
** Base > Base > Text Highlighter
* -------------------------------------------- */

.h1 span,
.h2 span,
.h3 span,
.h4 span,
.h5 span,
.h6 span,
.highlight {
  position: relative;
  z-index: $z-index--2;
  display: inline-block;
  color: $color__dirty-white;
}

.highlight:after {
  position: absolute;
  top: 0.3rem;
  right: 0;
  // left: -0.1em;
  left: -0.01rem;
  z-index: $z-index--minus-1;
  // width: 102%;
  width: 101%;
  height: 92%;
  background: $gradient-standard--270deg;
  content: '';
}
