// //////////////////////////////
// IMPORT ABSTRACT CSS FILES
//
// The abstracts/ directory gathers all Sass tools and helpers used across the project.
// Every global variable, function, mixin and placeholder should be put in here.
// The rule of thumb for all files within the abstracts/ directory is that it should
// not output a single line of CSS when compiled on its own. These are nothing but Sass helpers.
@import '../../scss/abstracts/breakpoint';
@import '../../scss/abstracts/functions';
@import '../../scss/abstracts/mixins';
@import '../../scss/abstracts/placeholders';
@import '../../scss/abstracts/variables';

/*--------------------------------------------------------------
# components / Grid Icons
--------------------------------------------------------------*/


:local {
    .grid-icons {
      padding: 1rem;
      // background: red;
    }

    // .grid-icons__heading {
    //   // background: blue;
    //   margin-bottom: 3rem;
    // }

    .grid-icons__grid {
      display: flex;
      flex-wrap: wrap;
      // background: yellow;
    }
}
