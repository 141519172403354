// //////////////////////////////
// IMPORT ABSTRACT CSS FILES
//
// The abstracts/ directory gathers all Sass tools and helpers used across the project.
// Every global variable, function, mixin and placeholder should be put in here.
// The rule of thumb for all files within the abstracts/ directory is that it should
// not output a single line of CSS when compiled on its own. These are nothing but Sass helpers.
@import '../abstracts/breakpoint';
@import '../abstracts/functions';
@import '../abstracts/mixins';
@import '../abstracts/placeholders';
@import '../abstracts/variables';
//
//
/* ----------------------------------------------
** Base > Helpers
* -------------------------------------------- */

/* Hide visually and from screen readers */
.hidden {
  display: none;
}

/*  Hide only visually, but have it available for screen readers:
**  http://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
.visuallyhidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: none;

  /* Extends the .visuallyhidden class to allow the element
  ** to be focusable when navigated to via the keyboard:
  ** https://www.drupal.org/node/897638 */
  &.focusable:active,
  &.focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }
}

/* Hide visually and from screen readers, but maintain layout */
.invisible {
  visibility: hidden;
}

/* Change direciton to Right to Left */
.direction--rtl {
  direction: rtl;
}
