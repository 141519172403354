// //////////////////////////////
// IMPORT ABSTRACT CSS FILES
//
// The abstracts/ directory gathers all Sass tools and helpers used across the project.
// Every global variable, function, mixin and placeholder should be put in here.
// The rule of thumb for all files within the abstracts/ directory is that it should
// not output a single line of CSS when compiled on its own. These are nothing but Sass helpers.
@import '../../scss/abstracts/breakpoint';
@import '../../scss/abstracts/functions';
@import '../../scss/abstracts/mixins';
@import '../../scss/abstracts/placeholders';
@import '../../scss/abstracts/variables';

/*--------------------------------------------------------------
# components / Buttons
--------------------------------------------------------------*/


:local {
  // //////////////////////////////
  // Buttons > Button Wrapper

  .button-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .button-wrapper svg {
    width: 1.2rem;
    height: auto;
    margin-right: 0.4rem;
    pointer-events: none;
  }

  // //////////////////////////////
  // Buttons > Button Loading
  .button__button-loading--disabled {
    cursor: not-allowed;
  }

  // //////////////////////////////
  // Buttons > Default Button Tag

  button {
    @include font-lato__22--400-secondary;

    padding: 0;
    margin: 0;
    background-color: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }

  // //////////////////////////////
  // Buttons > Button Placeholder

  %button {
    @include font-lato__22--400-secondary;
    @include element-fade-effect;

    display: inline-block;
    // height: 2.68rem;
    // height: 1.95rem;
    height: 2.4rem;
    padding: 0.39rem 1.5rem;
    text-align: center;

    // text-transform: uppercase;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    background-repeat: no-repeat;
    border: none;
    border-radius: 0;
    box-shadow: none;
    transition: background-color $animation-speed--500 ease-in,
      color $animation-speed--500 ease-out,
      border $animation-speed--500 ease-in;
    backface-visibility: hidden;
  }


  // //////////////////////////////
  // Buttons > Button One

  .button__one,
  .button__one--dark {
    @extend %button;

    // @include background-image-moving-animation;

    color: $color__dirty-white;
    background-color: transparent;
    background-size: 0% 100%; // this should be the same as the animation start frame
    border: 0.095rem solid $color__dirty-white;
    background-position-x: 0%; // this should be the same as the animation start frame
    background-position-y: 100%; // this should be the same as the animation start frame


    &:link {
      color: $color__dirty-white;
      text-decoration: none;
      background-color: transparent;
    }

    &:visited {
      color: $color__dirty-white;
      text-decoration: none;
      background-color: transparent;
    }

    &:hover,
    &:focus,
    &:active {
      @include background-image-fill-animaiton;

      color: $color__dirty-white;
      text-decoration: none;
      outline: 0;
    }
  }



  // //////////////////////////////
  // Buttons > Button One > Dark

  .button__one--dark {
    color: $color__spacegrey;
    border: 0.095rem solid $color__spacegrey;

    &:link {
      color: $color__spacegrey;
    }

    &:visited {
      color: $color__spacegrey;
    }

    &:hover,
    &:focus,
    &:active {
      color: $color__dirty-white;
      // background-color: $color__spacegrey;
      border: 0.095rem solid $color__dirty-white;
    }
  }


  // //////////////////////////////
  // Buttons > Button Two


  .button__two,
  .button__two--dark {
    @extend %button;

    color: $color__spacegrey;
    background-color: $color__dirty-white;

    background-size: 0% 100%; // this should be the same as the animation start frame
    border: 0.095rem solid $color__spacegrey;
    background-position-x: 0%; // this should be the same as the animation start frame
    background-position-y: 100%; // this should be the same as the animation start frame

    &:link {
      color: $color__dirty-white;
      text-decoration: none;
    }

    &:visited {
      color: $color__dirty-white;
      text-decoration: none;
    }

    &:hover,
    &:focus,
    &:active {
      @include background-image-fill-animaiton;

      color: $color__light-grey;
      text-decoration: none;
      background-color: $color__dirty-white;
      border: 0.095rem solid $color__dirty-white;
      outline: 0;
    }
  }


  // //////////////////////////////
  // Buttons > Button Two > Dark

  .button__two--dark {
    color: $color__dirty-white;
    background-color: $color__spacegrey;
    border: 0.095rem solid $color__dirty-white;

    &:link {
      color: $color__dirty-white;
    }

    &:visited {
      color: $color__dirty-white;
    }

    &:hover,
    &:focus,
    &:active {
      color: $color__dirty-white;
      background-color: $color__spacegrey;
      border: 0.095rem solid $color__dirty-white;
    }
  }


  // //////////////////////////////
  // Buttons > Button Three


  .button__three {
    @extend %button;

    height: auto;
    max-height: 2.68rem;
    padding: 0.05rem 0.3rem;
    color: $color__dirty-white;
    background-image: $gradient-standard--270deg;
    background-size: 100% 100%;
    border: none;
    background-position-x: 100%;
    background-position-y: 100%;

    &:link {
      color: $color__dirty-white;
      text-decoration: none;
    }

    &:visited {
      color: $color__dirty-white;
      text-decoration: none;
    }

    &:hover,
    &:focus,
    &:active {
      @include background-image-fill-animaiton--dark;
      // @include background-image-moving-animation;

      color: $color__dirty-white;
      text-decoration: none;
      background-color: $color__sharp-blue;
      outline: 0;
    }
  }


  // //////////////////////////////
  // Buttons > Button Four


  .button__four,
  .button__four--dark {
    @extend %button;

    height: auto;
    max-height: 2.68rem;
    // padding: 0.05rem 0.3rem;
    // padding: 0.3rem;
    // padding-bottom: 0.3rem;
    // margin-bottom: -0.3rem;
    padding: 0.2rem;
    color: $color__dirty-white;
    background-image: $gradient-standard--270deg;
    background-size: 100% 10%;
    border: none;
    background-position-x: 100%;
    background-position-y: 100%;

    &:link {
      color: $color__dirty-white;
      text-decoration: none;
    }

    &:visited {
      color: $color__dirty-white;
      text-decoration: none;
    }

    &:hover,
    &:focus,
    &:active {
      @include background-image-fill-animaiton--from-down-to-up($gradient-standard--270deg, $animation-speed--200);

      color: $color__dirty-white;
      text-decoration: none;
      // background-color: $color__creative-red;
      outline: 0;
    }
  }


  // //////////////////////////////
  // Buttons > Button Four > Dark


  .button__four--dark {
    @extend %button;

    color: $color__spacegrey;

    &:link {
      color: $color__spacegrey;
    }

    &:visited {
      color: $color__spacegrey;
    }

    &:hover,
    &:focus,
    &:active {
      @include background-image-fill-animaiton--from-down-to-up($gradient-standard--270deg, $animation-speed--200);
      // @include background-image-moving-animation;

      color: $color__dirty-white;
      text-decoration: none;
      // background-color: $color__creative-red;
      outline: 0;
    }
  }


  // //////////////////////////////
  // Button Full Width

  .button--full-width {
    width: 100%;
  }

}

