@charset 'UTF-8';


  /* ----------------------------------------------
  ** STYLES
  * -------------------------------------------- */
  //
  // Adapted from https://github.com/HugoGiraudel/sass-boilerplate/
  // Credit to: https://github.com/HugoGiraudel
  //
  //
  // README:
  // The styles file (usually labelled styles.scss or index.scss) should be the only Sass file
  // from the entire code base that does not begin with an underscore.
  // This file should ONLY contain @import and comments.
  //
  //
  //
  /* ----------------------------------------------
  ** TABLE OF CONTENTS:
  * -------------------------------------------- */
  //
  // Abstracts:
    // breakponts
    // Functions
    // Mixins
    // Placeholders
    // Variables

  /*
  * Vendor:
    * Sanitize

  * Base:
    * Base
    * Fonts
    * Grid
    * Helpers
    * Typography

  * Themes:
    * Default Theme

  * Shame File

  ----------------------------------------------- */
  //
  // ----------------------------------------------
  //  x. ABSTRACTS
  // -------------------------------------------- */
  //
  // The abstracts/ directory gathers all Sass tools and helpers used across the project.
  // Every global variable, function, mixin and placeholder should be put in here.
  // The rule of thumb for all files within the abstracts/ directory is that it should
  // not output a single line of CSS when compiled on its own. These are nothing but Sass helpers.
  //
  // @import
  //   'abstracts/breakpoint',
  //   'abstracts/functions',
  //   'abstracts/mixins',
  //   'abstracts/placeholders',
  //   'abstracts/variables';
  //


  /* ----------------------------------------------
  ** 1. VENDOR
  * -------------------------------------------- */
  /*
  ** Most projects will have a vendor/ directory containing all the CSS files from external
  ** libraries and frameworks – Normalize, Sanitize, Bootstrap, etc. Putting those aside in the same directory
  ** is a good way to say “Hey, this is not from me, not my code, not my responsibility”. If you have
  ** to override a section of any vendor, I recommend you have an new directory called vendors-extensions/
  ** in which you may have files named exactly after the vendors they overwrite. For instance,
  ** vendors-extensions/_bootstrap.scss is a file containing all CSS rules intended to re-declare some of
  ** Bootstrap’s default CSS.
  ** This is to avoid editing the vendor files themselves, which is generally not a good idea.
  */

:global {
  @import
    './scss/vendor/sanitize';


  /* ----------------------------------------------
  ** 2. BASE
  * -------------------------------------------- */
  /*
  ** The base/ directory holds what we might call the boilerplate code for the project.
  ** In there, you might find some typographic rules, a base.scss that defes some standard
  ** styles for commonly used HTML elements, etc.
  ** The base style creates the foundation for 'everything else' to be built on.
  */

  @import
    './scss/base/base',
    './scss/base/grid',
    './scss/base/helpers',
    './scss/base/typography';


  /* ----------------------------------------------
  ** 3. THEMES
  * -------------------------------------------- */
  /*
  ** On large sites and applications, it is not unusual to have different themes.
  */

  // @import
  //   './scss/themes/default';


  /* ----------------------------------------------
  ** 4. SHAME FILE
  * -------------------------------------------- */
  /* The idea of shame.css is that you have a totally new stylesheet reserved just
  ** for your hacky code. The code you have to write to get the release out on time,
  ** but the code that makes you ashamed.
  ** READ: https://csswizardry.com/2013/04/shame-css/
  */

  @import
    './scss/shame';
}


/* ----------------------------------------------
** 2. BASE
* -------------------------------------------- */

@import
  './scss/base/fonts'; // css with @font-face has to be outside of :global due to this issue = https://github.com/css-modules/css-modules/issues/95
