// //////////////////////////////
// IMPORT ABSTRACT CSS FILES
//
// The abstracts/ directory gathers all Sass tools and helpers used across the project.
// Every global variable, function, mixin and placeholder should be put in here.
// The rule of thumb for all files within the abstracts/ directory is that it should
// not output a single line of CSS when compiled on its own. These are nothing but Sass helpers.
@import '../abstracts/breakpoint';
@import '../abstracts/functions';
@import '../abstracts/mixins';
@import '../abstracts/placeholders';
@import '../abstracts/variables';
//
//
/* ----------------------------------------------
** Base > Typography
* -------------------------------------------- */

/* ----------------------------------------------
** Base > Typography > Headings
* -------------------------------------------- */

// //////////////////////////////
// GROUP NAME: font-lato__36

h1,
.font-lato__36--700 {
  @include font-lato__36--700;
}

// //////////////////////////////
// GROUP NAME: font-lato__28

h2,
.font-lato__28--700 {
  @include font-lato__22--400;
}


// //////////////////////////////
// GROUP NAME: font-lato__25

h3,
.font-lato__25--700 {
  // @include font-lato__25--700;

}

// //////////////////////////////
// GROUP NAME: font-lato__24

h4,
.font-lato__24--700 {
  // @include font-lato__24--700;

}


// //////////////////////////////
// GROUP NAME: font-lato__22

h5,
.font-lato__22--700 {
  // @include font-lato__22--700;

}


/* ----------------------------------------------
** Base > Typography > Fonts Types
* -------------------------------------------- */


// //////////////////////////////
// GROUP NAME: font-lato__18

.font-lato__18--700 {
  @include font-lato__18--700;
}

// //////////////////////////////
// GROUP NAME: font-lato__16

p, // [BASE FONT]
.font-lato__16--400 {
  @include font-lato__16--400;
}

.font-lato__16--700 {
  @include font-lato__16--700;
}

.font-lato__16--900 {
  @include font-lato__16--900;
}


// //////////////////////////////
// GROUP NAME: font-lato__14

.font-lato__14--700 {
  @include font-lato__14--700;
}



/* ----------------------------------------------
** Base > Typography > Small/Big Tags
* -------------------------------------------- */

small {
  font-size: if(
    variable-exists(font__small--size),
    $font__small--size,
    83.3333%
  );
}

big {
  font-size: if(variable-exists(font__big--size), $font__big--size, 125%);
}
