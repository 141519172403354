// //////////////////////////////
// IMPORT ABSTRACT CSS FILES
//
// The abstracts/ directory gathers all Sass tools and helpers used across the project.
// Every global variable, function, mixin and placeholder should be put in here.
// The rule of thumb for all files within the abstracts/ directory is that it should
// not output a single line of CSS when compiled on its own. These are nothing but Sass helpers.
@import '../../scss/abstracts/breakpoint';
@import '../../scss/abstracts/functions';
@import '../../scss/abstracts/mixins';
@import '../../scss/abstracts/placeholders';
@import '../../scss/abstracts/variables';

/*--------------------------------------------------------------
# components / Grid Render
--------------------------------------------------------------*/

:local {
    .grid-render {
      padding-top: 3rem;
      padding-bottom: 2rem;
      // background-color: #FF0000;
    }

    .grid-render--gray {
      background-color: $color__light-grey;
    }

    .grid-render h1,
    .grid-render h2,
    .grid-render h3 {
      margin-top: 1rem;
      margin-bottom: 3rem;
    }

    .grid-render h4,
    .grid-render h5,
    .grid-render h6 {
      margin-top: 2rem;
      margin-bottom: 1rem;
    }

    .grid-render p {
      margin-bottom: 0.5rem;
    }

    // a {
    //   margin-top: 2.5rem;
    //   padding-top: 2.5rem;
    //   // color: red;
    // }
}
