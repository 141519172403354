// //////////////////////////////
// IMPORT ABSTRACT CSS FILES
//
// The abstracts/ directory gathers all Sass tools and helpers used across the project.
// Every global variable, function, mixin and placeholder should be put in here.
// The rule of thumb for all files within the abstracts/ directory is that it should
// not output a single line of CSS when compiled on its own. These are nothing but Sass helpers.
@import '../../scss/abstracts/breakpoint';
@import '../../scss/abstracts/functions';
@import '../../scss/abstracts/mixins';
@import '../../scss/abstracts/placeholders';
@import '../../scss/abstracts/variables';

/*--------------------------------------------------------------
# components / Navigation Mobile
--------------------------------------------------------------*/


:local {
    .navigation-mobile {
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: $z-index--8;
      display: flex;

      @include breakpoint($global-navigation-breakpoint) {
        display: none;
      }
    }

    .navigation-mobile__button {
      display: flex;
      align-items: center;
      flex: 1 1 auto;
      flex-direction: column;
      justify-content: flex-end;
      padding-top: 1rem;
      padding-bottom: 0.5rem;
      text-align: center;
      cursor: pointer;
      background-color: $color__spacegrey;
      transition: background-color $animation-speed--250 ease-in;

      &:visited {
        color: $color__dirty-white;
        text-decoration: none;
        fill: $color__dirty-white;
      }

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
        background-color: $color__lighter-grey;
        outline: 0;
        fill: $color__dirty-white;
      }
    }

    .navigation-mobile__button h6 {
      margin-top: 0.55rem;
      margin-bottom: 0;
      color: $color__purpley-grey;
    }

    .navigation-mobile__button__icon  {
      width: 2rem;
      height: 2rem;
      fill: $color__dirty-white !important;

    }


}
