// //////////////////////////////
// IMPORT ABSTRACT CSS FILES
//
// The abstracts/ directory gathers all Sass tools and helpers used across the project.
// Every global variable, function, mixin and placeholder should be put in here.
// The rule of thumb for all files within the abstracts/ directory is that it should
// not output a single line of CSS when compiled on its own. These are nothing but Sass helpers.
@import '../../scss/abstracts/breakpoint';
@import '../../scss/abstracts/functions';
@import '../../scss/abstracts/mixins';
@import '../../scss/abstracts/placeholders';
@import '../../scss/abstracts/variables';

/*--------------------------------------------------------------
# components / Info Box Three Column
--------------------------------------------------------------*/

$main-page-breakint: $laptop-m;

:local {
    .info-box {
      position: relative;
      background-color: $color__spacegrey;
    }

    .info-box h2 {
      margin-bottom: 1.5rem;
    }

    .info-box p {
      margin-top: 0.7rem;
      margin-bottom: 1.5rem;
    }

    .info-box__overlay,
    .info-box__wrapper {
      padding-top: 1.5rem;
      padding-bottom: 2rem;
      color: $color__dirty-white;
      background-color: $color__spacegrey;

      @include breakpoint($main-page-breakint) {
        padding-right: 0;
      }
    }

    // //////////////////////////////
    // Info Box Three Column > Overlay

    .info-box__overlay {
      @include element-fade-effect($animation-speed--250);

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding-top: 2rem;
      padding-right: 5%;
      padding-bottom: 2rem;
      padding-left: 5%;
      overflow-y: scroll;
      color: $color__dirty-white;
      background-color: $color__spacegrey;
      scroll-behavior: smooth;
    }

    .info-box__overlay__close {
      position: absolute;
      top: 2rem;
      right: 2rem;
      cursor: pointer;
    }

    .info-box__overlay__close svg {
      width: 2rem;
      height: 2rem;
      fill: $color__dirty-white !important;
    }

    // //////////////////////////////
    // Info Box Three Column > Buttons

    .info-box__button-list {
      display: flex;
      flex-wrap: wrap;
    }

    .info-box__button-list__button {

      display: flex;
      align-items: center;
      flex: 1 1 18rem;
      padding: 0.4rem;
      margin-right: 1rem;
      margin-bottom: 1rem;
      color: $color__dirty-white;
      cursor: pointer;
      background-color: $color__spacegrey;
      border-radius: 0.2rem;
      transition: background-color $animation-speed--250 ease-in;

      &:visited {
        color: $color__dirty-white;
        text-decoration: none;
      }

      &:hover,
      &:focus,
      &:active {
        color: $color__dirty-white;
        text-decoration: none;
        background-color: $color__lighter-grey;
        outline: 0;
      }
    }

    .info-box__button__text {
      flex: 1 1 100%;
      text-align: left;
    }

    .info-box__button__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 4rem;
      margin-right: 0.5rem;
      color: $color__sharp-blue;
      background-color: $color__dirty-white;
      border-radius: 100%;
    }

    .info-box__button__icon svg {
      width: 4rem;
      height: 4rem;
      padding: 1.2rem;
    }


}

