
// //////////////////////////////
// IMPORT ABSTRACT CSS FILES
//
// The abstracts/ directory gathers all Sass tools and helpers used across the project.
// Every global variable, function, mixin and placeholder should be put in here.
// The rule of thumb for all files within the abstracts/ directory is that it should
// not output a single line of CSS when compiled on its own. These are nothing but Sass helpers.
@import '../../scss/abstracts/breakpoint';
@import '../../scss/abstracts/functions';
@import '../../scss/abstracts/mixins';
@import '../../scss/abstracts/placeholders';
@import '../../scss/abstracts/variables';

/*--------------------------------------------------------------
# components / LogosClient
--------------------------------------------------------------*/

// //////////////////////////////
// Default LogoClient Styling

:local {

  .logos-client {
    flex: 1 1 5rem; // disable this
    width: auto; // disable this
    // width: 4rem; // enable this
    height: 4rem;
    padding: 0.5rem;
    margin: 0.5rem;
    // background: #222;
    border-radius: 8px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }
}
